export interface IResponseData {
  success: boolean
  data: ICommentRecommendation
}

export interface ICommentRecommendation {
  id: string
  comment: string
  recommendation: string
  deleted_at: Date | null
  updated_at: Date
}
export interface IQuizAttemptInfo {
  gradeId: string
  quizName: string
  multipleChoiceScores: number
  essayScores?: number
  comment?: string | null
  recommendation?: string | null
}

export interface IQuizAttemptInfoResponse {
  id: string
  attempt_grade: number
  comment: string | null
  essay_grade: number
  marker_id: string
  multiple_choice_grade: number
  quiz_attempt_id: string
  status: string
  title: string
  recommendation?: string | null
}

export interface IQuizAttemptGradingQuestion {
  id: string
  question_id: string
  graded: boolean
  is_correct: boolean
  attempt_answers: {
    id: string
    requirement_id: string | null
  }[]
  index: number
}

export interface IResponse<T> {
  success: boolean
  data: T
}

export interface IAttemptAnswerData {
  id: string
  attempt_answers: string[]
  is_present: boolean
  index: number
  question_id: string
}

export interface IAttemptAnswer {
  id: string
  requirement_id: string | null
}

export interface IFilterTabProps {
  id: string
  status: string
  attempt_answers: string[]
  index: number
  question_id: string
}

export interface QuestionMatching {
  id: string
  answer_id: string
  content: string
  question_id: string
  answer: {
    id: string
    answer: string
    answer_position: number
    is_correct: boolean
  }
}

export interface File {
  id: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  dom_id: string | null
  type: string
  object_id: string
  resource_id: string
  course_id: string | null
  for_editor: boolean
  resource: {
    id: string
    name: string
    file_key: string
    stream_url: string | null
    cloudflare_video_id: string | null
    status: string
    url: string
    url_expired_in: string | null
    sub_url: string | null
  }
}

export interface Requirement {
  id: string
  question_id: string
  name: string
  description: string
  explanation: string | null
  files: File[]
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export interface Question {
  id: string
  key: string
  question_content: string
  level: string
  qType: string
  display_type: string
  solution: string
  is_single_question: boolean
  status: string
  hint: string
  explanation: string | null
  is_self_reflection: boolean
  files: []
  question_report: {
    average_time: number
    ratio: number
  }
  question_topic: {
    id: string
    name: string
    description: string
    display_type: string
    number_of_multiple_choice_questions: number
    number_of_essay_questions: number
    files: File[]
  }
  answers: {
    id: string
    answer: string
    answer_position: number
    is_correct: boolean
    question_id: string
  }[]
  question_matchings: QuestionMatching[]
  response_option_answer?: string
  response_option?: string
  requirements: Requirement[]
}

export interface Answer {
  answer_id: string
  question_id: string
  answer_position: number
  answer_text: string
}

export interface AnswerMapping {
  id: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  question_id: string | null
  answer: string
  is_correct: boolean
  feedback: string | null
  active: boolean | null
  answer_position: number
}
export interface AnswerMatchingMapping {
  id: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  question_id: string
  content: string
  answer_id: string
  answer: AnswerMapping
}
export interface AnswerPositionMapping {
  answer_id: string
  position: number
}
export interface QuestionAnswer {
  id: string
  question_id: string
  question_answer_id: string[]
  short_answer: string | null
  answer: Answer[]
  is_correct: boolean
  time_spent: number
  question: Question
  answer_matching_mapping: AnswerMatchingMapping[]
  answer_position_mapping: AnswerPositionMapping[]
  question_content?: string
  active?: string | null
}

export enum RESPONSE_OPTION {
  WORD = 'WORD',
  SHEET = 'SHEET',
}
