import GradeIcon from '../GradeIcon'

interface DetailProps {
  item: any
  onEdit: (item: any) => void
  onDelete: (item: any) => void
}

const Detail = ({ item, onEdit, onDelete }: DetailProps) => {
  return (
    <div
      className='comment-grade'
      key={item.begin + item.end}
      data-begin={item.begin}
      data-end={item.end}
    >
      {item.comment && (
        <div className='comment'>
          <label>Comment:</label>
          <div className='content' dangerouslySetInnerHTML={{ __html: item.comment }}></div>
        </div>
      )}
      {item.grade !== undefined && (
        <label className='grade'>
          Grade: <span>{item.grade}</span>
        </label>
      )}
      <div className='edit-delete'>
        <div className='edit' onClick={() => onEdit(item)}>
          <GradeIcon iconType='notepad-edit' />
        </div>
        <div className='delete' onClick={() => onDelete(item)}>
          <GradeIcon iconType='trash' />
        </div>
      </div>
    </div>
  )
}

export default Detail
