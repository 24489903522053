import { ReactNode } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'

interface IProps {
  okOnClick: any
  className?: string
  classNameSubmit?: string
  classNameCancel?: string
  cancelButtonCaption: string
  okButtonCaption: string
  cancelClick?: () => void
  loading?: boolean
  linkCancel?: string
  bg?: 'danger' | 'primary'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  buttonIcon?: boolean
  buttonSubmitIcon?: string
  cancelButton?: ({
    cancelButtonCaption,
    cancelClick,
    loading,
  }: {
    cancelButtonCaption: string
    cancelClick?: () => void
    loading?: boolean
  }) => ReactNode
  isListScreen?: boolean
  showCancelBtn?: boolean
  showThirdBtn?: boolean
  classNameThird?: string
  thirdButtonCaption?: string
  thirdOnClick?: any
  thirdButtonLoading?: boolean
}

const SAPPDialogButtonsCancelSubmit = ({
  okOnClick,
  className,
  classNameSubmit,
  classNameCancel = 'min-w-100px me-5',
  cancelButtonCaption,
  okButtonCaption,
  cancelClick,
  loading,
  linkCancel,
  bg,
  type,
  disabled,
  buttonIcon = false,
  buttonSubmitIcon,
  cancelButton,
  isListScreen = false,
  showCancelBtn = true,
  showThirdBtn = false,
  classNameThird,
  thirdButtonCaption,
  thirdOnClick,
  thirdButtonLoading,
}: IProps) => {
  return (
    <div className={`text-center ${className ?? ''}`}>
      {showCancelBtn &&
        (!cancelButton ? (
          <>
            {buttonIcon ? (
              <ButtonIcon
                title={cancelButtonCaption}
                className='btn btn-sapp-icon me-5'
                link={linkCancel}
                onClick={cancelClick}
                isListScreen={isListScreen}
              >
                <KTIcon iconName='black-left' className='fs-3 pe-1' iconType='outline' />
              </ButtonIcon>
            ) : (
              <ButtonSecondary
                type='button'
                title={cancelButtonCaption}
                onClick={cancelClick}
                link={linkCancel}
                className={`${classNameCancel}`}
                isListScreen={isListScreen}
              />
            )}
          </>
        ) : (
          cancelButton({
            cancelButtonCaption,
            cancelClick,
            loading,
          })
        ))}

      {buttonSubmitIcon ? (
        <ButtonIcon
          type={type}
          disabled={loading || disabled}
          title={okButtonCaption}
          onClick={okOnClick}
          loading={loading}
          isListScreen={isListScreen}
        >
          <KTIcon iconName={buttonSubmitIcon} className='fs-3 pe-1' iconType='outline' />
        </ButtonIcon>
      ) : (
        <ButtonPrimary
          type={type}
          disabled={loading || disabled}
          title={okButtonCaption}
          onClick={okOnClick}
          loading={loading}
          bg={bg}
          className={`${classNameSubmit ? classNameSubmit : 'min-w-100px'}`}
          isListScreen={isListScreen}
        />
      )}

      {showThirdBtn && (
        <ButtonPrimary
          type='button'
          disabled={thirdButtonLoading || disabled}
          title={thirdButtonCaption}
          onClick={thirdOnClick}
          loading={thirdButtonLoading}
          bg={bg}
          className={`${classNameThird ? classNameThird : 'min-w-100px'} ms-5`}
          isListScreen={isListScreen}
        />
      )}
    </div>
  )
}

export default SAPPDialogButtonsCancelSubmit
