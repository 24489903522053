import React from 'react'
import SappModal from './SappModal'

type Props = {
  src?: string
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>
}

function SappModalImage({ src, setSrc }: Props) {
  console.log({ setSrc })
  const handleClose = () => {
    setSrc(undefined)
  }

  return (
    <SappModal
      open={!!src}
      okButtonCaption={'Yes'}
      cancelButtonCaption={'No'}
      handleClose={handleClose}
      showFooter={false}
      title=""
    >
      <div className="w-fit max-w-full min-w-[100%] mx-auto md:min-h-[350px]">
        {src && (
          <img
            src={src}
            className="w-full max-h-[550px] object-contain h-full md:min-h-[500px] "
            alt={'image'}
          />
        )}
      </div>
    </SappModal>
  )
}

export default SappModalImage
