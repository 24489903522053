import { Skeleton } from 'antd'
import { IFilterTabProps } from 'src/type/grade'

interface TabSlidesProps {
  isLoading: boolean
  currentTabId: string
  filteredTabs: IFilterTabProps[]
  openGrid: boolean
  handleChangeTab: (data: IFilterTabProps) => void
}

const TabSlides = ({ isLoading, currentTabId, filteredTabs, openGrid, handleChangeTab }: TabSlidesProps) => {
  const onChangeQuestionAttempt = (item: IFilterTabProps) => {
    if (item.id === currentTabId) return

    handleChangeTab(item)
  }

  return (
    <div className='tabslides-container'>
      <div className={`tabslides-inner ${isLoading ? 'loading' : ''}`}>
        <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 1, width: '100%' }}>
          {filteredTabs?.length > 0 && (
            <ul className={`tabslides ${openGrid ? 'tabslides-grid' : ''}`}>
              {filteredTabs?.map((item) => (
                <li
                  key={item.id}
                  className={`${item.status} ${currentTabId === item.id ? 'viewed' : ''}`}
                  onClick={() => onChangeQuestionAttempt(item)}
                >
                  {item.index}
                </li>
              ))}
            </ul>
          )}
        </Skeleton>
      </div>
    </div>
  )
}

export default TabSlides
