import { useEffect, useState } from 'react'
import { CacheAPI } from 'src/apis/cache'
import ButtonLoading from 'src/components/base/button/ButtonLoading'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import {
  ACTIVE_POPUPCONFIRM,
  DEACTIVE_POPUPCONFIRM,
  REFRESH_POPUPCONFIRM,
} from 'src/constants/lang'
import { ICache } from 'src/type/cache'

type CacheButtonType = {
  cache: ICache
  confirm: any
}

export const CacheButton = ({ confirm, cache }: CacheButtonType) => {
  const [data, setData] = useState<ICache>(cache)
  const [status, setStatus] = useState<string | null>(null)

  const changeCacheStatus = async (id: string) => {
    try {
      const result = await CacheAPI.changeCacheStatus(id)
      setData(result.data)
    } catch (error) {
    } finally {
      setStatus(null)
    }
  }

  const clearCaching = async (id: string) => {
    try {
      await CacheAPI.deleteCachingViaPrefix(id)
    } catch (error) {
    } finally {
      setStatus(null)
    }
  }

  const activeAction = (e: ICache) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [ACTIVE_POPUPCONFIRM],
      onClick: () => {
        setStatus('ENABLE')
        changeCacheStatus(e.id)
      },
    })
  }

  const deActiveAction = (e: ICache) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DEACTIVE_POPUPCONFIRM],
      onClick: () => {
        setStatus('DISABLE')
        changeCacheStatus(e.id)
      },
    })
  }

  const clearAction = (e: ICache) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [REFRESH_POPUPCONFIRM],
      onClick: () => {
        setStatus('CLEAR')
        clearCaching(e.id)
      },
    })
  }

  if (data.status === 'ENABLED') {
    if (status === 'CLEAR') {
      return (
        <div className='d-flex justify-content-end'>
          <ButtonLoading size='small' title='In Progess' className='btn-loading me-4' />
          <ButtonSecondary
            className='mr-0 sapp-h-30px line-heigh-12'
            classNameTitle='text-fs-12 font-weight-600 text-primary-placeholder'
            title='Disable'
            disabled
          />
        </div>
      )
    }

    return (
      <div className='d-flex justify-content-end'>
        {status && <ButtonLoading size='small' title='In Progess' className='btn-loading' />}

        {!status && (
          <ButtonSecondary
            className='me-4 sapp-h-30px line-heigh-12'
            classNameTitle='text-fs-12 font-weight-600 text-primary-placeholder'
            title='Clear'
            onClick={() => clearAction(data)}
          />
        )}

        {!status && (
          <ButtonSecondary
            className='mr-0 sapp-h-30px line-heigh-12'
            classNameTitle='text-fs-12 font-weight-600 text-primary-placeholder'
            title='Disable'
            onClick={() => deActiveAction(data)}
          />
        )}
      </div>
    )
  }

  return (
    <div className='d-flex justify-content-end'>
      {!status && (
        <ButtonPrimary
          className='mr-0 sapp-h-30px line-heigh-12'
          classNameTitle='text-fs-12 font-weight-600'
          title='Enable'
          onClick={() => activeAction(data)}
        />
      )}
      {status && <ButtonLoading size='small' title='In Progess' className='btn-loading' />}
    </div>
  )
}
