import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import QuizResult from 'entrance-test-result-package'
import { useConfirm } from 'src/hooks/use-confirm'
import './participant.scss'

const TestResult = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [dataChart, setDataChart] = useState<[]>([])
  const [dataTable, setDataTable] = useState<any>()
  const handleClose = () => {
    navigate(-1)
  }

  const fetchDataChart = async (id: string | undefined) => {
    try {
      if (id) {
        const response = await EntranceApi.getDataChart(id)
        setDataChart(response.data.chart_data)
        setDataTable(response.data)
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (!id) return

    fetchDataChart(id)
  }, [id])

  /**
   * @description function này gọi API publish score
   */
  async function publishScore() {
    const res = await EntranceApi.publishScore(id)
    if (res.success) {
      fetchDataChart(id)
    }
  }

  const { confirm, contextHolder } = useConfirm()

  /**
   * @description function này để mở popup publish score
   */
  const handlePublishScore = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc muốn hiển thị kết quả chi tiết bài kiểm tra đầu vào cho học viên?',
      onClick: publishScore,
    })
  }

  return (
    <>
      {contextHolder}
      <div className='position-absolute end-0 top-0 p-3 '>
        <i
          className='ki-outline ki-cross fs-1'
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {/* <QuizResult
        dataChart={dataChart}
        dataTable={dataTable}
        onClick={() => navigate(`/entrance-test/list-entrance-test/quiz-result/table-quiz/${id}`)}
        onPublish={handlePublishScore}
        id={id}
        is_ops
      /> */}
    </>
  )
}

export default TestResult
