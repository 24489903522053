export const DEFAULT_ATTEMPT_DATA = {
  id: '',
  attempt_answers: [] as string[],
  is_present: false,
  index: 0,
  question_id: '',
}

export const STATUS_GRADED = {
  value: 'graded',
  label: 'Graded',
}

export const STATUS_CORRECT = {
  value: 'correct',
  label: 'Correct',
}

export const STATUS_INCORRECT = {
  value: 'incorrect',
  label: 'Incorrect',
}

export const STATUS_UNGRADED = {
  value: 'ungraded',
  label: 'Chưa chấm',
}

export const FILTER = [[STATUS_CORRECT, STATUS_GRADED], STATUS_INCORRECT, STATUS_UNGRADED]

export const DROPDOWN_ACTION = [
  {
    label: 'Comment & Recommendations',
    url: null,
    event: 'openAction',
    icon: null,
  },
  {
    label: 'Finish',
    url: null,
    event: 'finish',
    icon: 'info-circle-fill',
  },
  {
    label: 'Exit',
    url: '/dashboard',
    event: null,
    icon: 'info-circle-fill',
  },
]

export const COMMENTS = {
  Examiner_Comment: 'Examiner’s Comment',
  Recommendation: 'Recommendation',
  Cancel: 'Cancel',
  Save: 'Save',
}
