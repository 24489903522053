import React from 'react'
import "./HookFormCheckBoxTN.scss"

interface IProps {
  ktCheck?: boolean
  checkTarget?: string
  checked: boolean
  onChange: (e?: any) => void
  className?: string
  disabled?: boolean
  lowerOptions?: boolean
  state?: 'default' | 'error' | 'success' | 'primary' // Thêm prop state
  size?: 'small' | 'medium' | 'lager' // Thêm prop size
  value?: string | boolean
}

const STATE = {
  success:
    'sapp-success-checkbox',
  error:
    'sapp-error-checkbox',
  default: 'sapp-default-checkbox',
  primary:
    'sapp-primary-checkbox',
}

const SIZES = {
  small: 'small',
  medium: 'medium',
  lager: 'lager',
}

const SAPPCheckboxTN = ({
  ktCheck,
  checkTarget,
  checked,
  onChange,
  className,
  disabled,
  lowerOptions = false,
  state = 'default',
  size = 'medium',
  value
}: IProps) => {
  return (
    <div className={`sapp-checkbox ${className}`}>
      <input
        className={`d-block ${SIZES[size]
          } ${STATE[state]
          } ${disabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'} ${lowerOptions && 'border-none'
          }`}
        type='checkbox'
        data-kt-check={ktCheck}
        data-kt-check-target={checkTarget}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value?.toString()}
      />
    </div>
  )
}

export default SAPPCheckboxTN
