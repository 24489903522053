// export const convertLocalTimeToUTC = (currentTime: Date) => {
//     const offsetMinutes = currentTime.getTimezoneOffset()
//     const utcTime = new Date(currentTime.getTime() + offsetMinutes * 60 * 1000)

//     return utcTime
//   }

export const convertUTCToLocalTime = (utc_time: Date | string) => {
  return new Date(utc_time)
}

export function convertMsToMinutes(milliseconds: number) {
  const minutes = Math.round(milliseconds / 60000)
  return minutes
}

export function convertSToMinutes(seconds: number) {
  const minutes = Math.round(seconds / 60)
  return minutes
}
