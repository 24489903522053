import { uniqueId } from 'lodash'
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import EditorReader from '../base/editor/EditorReader'
import { replaceTextAlignCenterToWebKitCenter } from 'src/utils'
import { SappTitleSolution } from '../base/SappTitleSolution'
import { MY_COURSES } from 'src/constants/lang'
import { Question } from 'src/type/grade'

interface Icorrects {
  id: string;
  answer: string;
  answer_position: number;
  question_id: string;
  is_correct: boolean;
}
interface IProps {
  data: Question
  defaultAnswer: {
    id: string;
    idAnswer: string;
    value: string;
  }[]
  corrects?: Icorrects[]
  solution?: string;
}
const DragNDropPreivew = forwardRef(
  (
    {
      data,
      defaultAnswer,
      corrects,
      solution
    }: IProps,
  ) => {
    const str = data.question_content
    const parser = new DOMParser()
    const [questionContent, setQuestionContent] = useState<Document | null>(null);
    const [answerContent, setAnswerContent] = useState<Document | null>(null);
    const isSelfReflection = data?.is_self_reflection

    useEffect(() => {
      const doc = parser.parseFromString(str, 'text/html')
      const doc2 = parser.parseFromString(str, 'text/html')
      // if (refContent?.current) {
      const elements = doc.querySelectorAll('.question-content-tag')
      const elementsCorrects = doc2.querySelectorAll('.question-content-tag')
      if (corrects) {
        elementsCorrects.forEach((element: Element, index: number) => {
          element.outerHTML = `<span id="${element.id}" class="sapp-input-dragNDrop-answer corrects">
        <span id="${corrects[index]?.id}" class="flex justify-center w-full">${corrects[index]?.answer}</span>
        </span>`
        })
        elements.forEach((element: Element, index: number) => {
          if (defaultAnswer?.length > 0) {
            if (defaultAnswer?.[index]?.value !== '') {
              element.outerHTML = `<span  id="${element?.id
                }" class="sapp-input-dragNDrop-answer ${(defaultAnswer?.[index]?.idAnswer === corrects[index]?.id || isSelfReflection === true)
                  ? 'corrects'
                  : 'wrongs'
                }">
            <span id="${defaultAnswer?.[index]?.idAnswer
                }" class="flex justify-center w-full">${defaultAnswer?.[index]?.value
                }</span>
            </span>`
            } else {
              element.outerHTML = `<span id="${element.id}" class= "sapp-input-dragNDrop-answer ${(isSelfReflection === true)
                ? 'corrects'
                : 'wrongs'
                }">
              <span class="sapp-input-dragNDrop-empty"></span>
            </span>`
              //   })
            }
          } else {
            element.outerHTML = `<span id="${element.id}" class= "sapp-input-dragNDrop-answer ${(isSelfReflection === true)
              ? 'corrects'
              : 'wrongs'
              }">
            <span class="sapp-input-dragNDrop-empty"></span>
          </span>`
          }
        })
        setAnswerContent(doc2)
        setQuestionContent(doc)
      }
      // }
    }, [defaultAnswer, corrects])

    return (
      <div>
        {questionContent && (
          <div>
            <div>
              <EditorReader
                className='questions'
                text_editor_content={
                  replaceTextAlignCenterToWebKitCenter(questionContent?.documentElement.querySelector('body')
                    ?.innerHTML || '')
                }
              />
            </div>
          </div>
        )}
        {answerContent && (
          <div>
            <h3 className="mt-8">
              Correct Answer
            </h3>
            <EditorReader
              className='questions mt-2'
              text_editor_content={
                replaceTextAlignCenterToWebKitCenter(answerContent?.documentElement.querySelector('body')
                  ?.innerHTML || '')
              }
            />
          </div>
        )}
      </div>
    )
  },
)
export default DragNDropPreivew
