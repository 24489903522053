import { useEffect, useMemo } from 'react'
import HookFormRadioTN from '../base/radiobutton/HookFormRadioTN';
import { getUppercaseByNumber } from 'src/utils';
import EditorReader from '../base/editor/EditorReader';
import "./index.scss"
import { SappTitleSolution } from '../base/SappTitleSolution';
import { MY_COURSES } from 'src/constants/lang';
import { Question } from 'src/type/grade';
import { Control, UseFormSetValue } from 'react-hook-form';

interface IFormValues {
  answer: string;
}

export type IPreviewProp = {
  data?: Question;
  control: Control<IFormValues>
  name: string
  corrects?: { [key: string]: boolean }
  defaultValues?: string[];
  setValue: UseFormSetValue<any>
  solution?: string
}

type IAnswers = {
  answer_position: number
}
const OneChoiceQuestion = ({
  data,
  control,
  corrects,
  name,
  defaultValues,
  setValue,
  solution
}: IPreviewProp) => {
  useEffect(() => {
    if (defaultValues) {
      setValue(name, defaultValues)
    } else {
      setValue(name, '')
    }
  }, [defaultValues])
  const convertAnswer = useMemo(() => {
    let answers = []
    let number = 0

    if (data?.answers) {
      const dataAnswers = [...data?.answers]
      dataAnswers.sort(
        (a: IAnswers, b: IAnswers) => a?.answer_position - b?.answer_position,
      )
      for (let e of dataAnswers) {
        number++
        answers.push({
          label: `${getUppercaseByNumber(number)}. ${e?.answer}`,
          value: e?.id,
        })
      }
    }
    return answers
  }, [data])

  return (
    <>
      <div
        id="hightlight_area"
      >
        <EditorReader
          text_editor_content={data?.question_content}
          className="sapp-questions"
        />
      </div>
      <div
        className="sapp-answer-wrapper"
        style={{
          flexDirection: 'column',
        }}
      >
        <HookFormRadioTN
          options={convertAnswer || []}
          control={control}
          name={name || 'answer'}
          corrects={corrects}
          defaultValue={defaultValues}
        />
      </div>
    </>
  )
}
export default OneChoiceQuestion
