import React from 'react'
import './HookFormRadioTN.scss'

interface IProps {
  ktCheck?: boolean
  checkTarget?: string
  checked: boolean
  onChange: (e?: any) => void
  className?: string
  disabled?: boolean
  name?: string
  value?: string | boolean
  state?: 'default' | 'error' | 'success'
  size?: 'small' | 'medium' | 'lager'
}

const STATE = {
  success: 'success',
  error: 'error',
  default: 'default',
}

const SIZES = {
  small: 'small',
  medium: 'medium',
  lager: 'lager',
}

const SAPPRadio = ({
  ktCheck,
  checkTarget,
  checked,
  onChange,
  className,
  disabled,
  name,
  value,
  state = 'default',
  size = 'medium',
}: IProps) => {
  return (
    <div className={`sapp-radio ${SIZES[size]}`}>
      <input
        name={name}
        className={`${STATE[state]} ${disabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'
          }`}
        type='radio'
        data-kt-check={ktCheck}
        data-kt-check-target={checkTarget}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value?.toString()}
      />
    </div>
  )
}

export default SAPPRadio
