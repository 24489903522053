import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import SAPPRadio from './SAPPRadio'
import { uniqueId } from 'lodash'
import YourAnswer from '../tag/YourAnswer'
import './HookFormRadioTN.scss'

interface IHookFormRadioTNProps {
  name: string
  control: Control<any>
  defaultValue?: unknown
  options: Array<{
    label?: string
    value: string | boolean
    description?: string
    disabled?: boolean
  }>
  direction?: 'horizontal' | 'vertical'
  separator?: boolean
  justify?: 'between' | 'start' | 'center' | 'end'
  gap?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  labelClass?: string
  labelClassChecked?: string
  disabled?: boolean
  corrects?: { [key: string]: boolean }
}

const HookFormRadioTN = ({
  name,
  control,
  defaultValue,
  options,
  direction,
  separator,
  gap,
  onChange,
  justify = 'between',
  labelClass = '',
  labelClassChecked = '',
  disabled,
  corrects,
}: IHookFormRadioTNProps) => {
  const count_items = options?.length - 1
  gap = gap ? gap : direction === 'horizontal' ? 'gap-6' : 'gap-4'
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <div
              className={
                'sapp-hook-form-radio-group ' +
                (direction === 'horizontal'
                  ? `_horizontal d-flex justify-content-${justify} flex-wrap ${gap ? 'gap-' + gap : ''
                  }`
                  : `_vertical d-flex flex-column ${gap ? 'gap-' + gap : ''}`)
              }
            >
              {options.map((option, index) => {
                let state: 'error' | 'default' | 'success' | undefined
                let stateLabel: string = 'text-bw-1'
                let checked: boolean = option.value.toString() === field.value
                let correctCheck: boolean =
                  corrects?.[option.value as string] || false
                if (!!corrects) {
                  if (corrects?.[option.value as string]) {
                    state = 'success'
                    stateLabel = 'text-state-success'
                  } else if (checked) {
                    state = 'error'
                    stateLabel = 'text-state-error'
                  }
                }

                return (
                  <div
                    key={uniqueId('check')}
                    className={`${!!corrects && 'sapp-pointer-events-none'}`}
                  >
                    <div className="d-flex">
                      <label
                        className={`sapp-label-radio ${(
                          option.disabled !== undefined
                            ? option.disabled
                            : disabled
                        )
                          ? 'opacity-60 cursor-not-allowed'
                          : 'cursor-pointer'
                          }`}
                      >
                        <SAPPRadio
                          name={field.name}
                          disabled={
                            option.disabled !== undefined
                              ? option.disabled
                              : disabled
                          }
                          onChange={(e) => {
                            onChange && onChange(e)
                            field.onChange(e.target.value)
                          }}
                          key={index}
                          value={option.value.toString()}
                          checked={checked}
                          className="flex-none mt-[3px]"
                          size="small"
                          state={state}
                        />
                        <span className="sapp-answer fw-normal">
                          <div
                            className={`${option.value === field.value
                              ? ` ${stateLabel} ${labelClassChecked ? labelClassChecked : ''
                              }`
                              : ` ${stateLabel} ${labelClass ? labelClass : ''
                              }`
                              } sapp-answer-item`}
                          >
                            {option.label}
                            <YourAnswer
                              show={checked && !!corrects}
                            ></YourAnswer>
                          </div>
                          {option.description && (
                            <div className="text-sm text-gray-500">
                              {option.description}
                            </div>
                          )}
                        </span>
                      </label>
                    </div>
                    {false &&
                      index !== count_items &&
                      (separator === undefined || separator === true) && (
                        <div className="separator separator-dashed my-5"></div>
                      )}
                  </div>
                )
              })}
            </div>
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )
      }}
    />
  )
}

export default HookFormRadioTN
