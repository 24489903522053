import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { ICache, ICacheList } from 'src/type/cache'

export class CacheAPI {
  static getCacheList({
    page_index = 1,
    page_size = 10,
  }: {
    page_index?: number
    page_size: number
  }): Promise<IResponse<ICacheList>> {
    return fetcher(`/caching-managers?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
    })
  }

  static changeCacheStatus(id: string): Promise<IResponse<ICache>> {
    return fetcher(`/caching-managers/${id}/change-status`, {
      method: 'GET',
    })
  }

  static getDetailPrefix(id: string): Promise<IResponse<ICache>> {
    return fetcher(`/caching-managers/${id}`, {
      method: 'GET',
    })
  }

  static deleteCachingViaPrefix(id: string): Promise<IResponse<any>> {
    return fetcher(`/caching-managers/${id}/keys`, {
      method: 'DELETE',
    })
  }

  static deleteAllCaching(): Promise<IResponse<any>> {
    return fetcher(`/caching-managers/keys`, {
      method: 'DELETE',
    })
  }

  static getDetailCachingKeys({
    id,
    page_size = 10,
    page_index = 1,
  }: {
    id: string
    page_index?: number
    page_size?: number
  }): Promise<IResponse<any>> {
    return fetcher(`/caching-managers/${id}/keys?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
    })
  }

  static deleteSpecificKey(key: string): Promise<IResponse<any>> {
    return fetcher(`/caching-managers/keys/${key}`, {
      method: 'DELETE',
    })
  }
}
