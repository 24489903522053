import { Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DROPDOWN_ACTION } from 'src/constants/grade'
import GradeIcon from '../GradeIcon'

interface IDropDownActionsProps {
  openGrid: boolean
  setOpenGrid: (openGrid: boolean) => void
  setOpenRecommendation: (openRecommendation: boolean) => void
}

const DropDownActions = ({ openGrid, setOpenGrid, setOpenRecommendation }: IDropDownActionsProps) => {
  const navigate = useNavigate()
  const [openAction, setOpenAction] = useState<boolean>(false)
  const [canFinish, setCanFinish] = useState<boolean>(true)
  const actionRef = useRef<HTMLDivElement>(null)

  // Trigger finish grading action
  const onFinishAction = () => {
    window.dispatchEvent(new CustomEvent('onFinishGrading'))
  }

  // Handle click outside action dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openAction && actionRef.current && !actionRef.current.contains(event.target as Node)) {
        setOpenAction(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openAction])

  useEffect(() => {
    const afterFinishGrading = () => setCanFinish(false)

    window.addEventListener('onFinishedGrading', afterFinishGrading)

    return () => {
      window.removeEventListener('onFinishedGrading', afterFinishGrading)
    }
  }, [setCanFinish])

  return (
    <div className='d-flex grade-header-icon-wrapper'>
      <span className={`grade-header-icon ${openGrid ? 'open-grid' : ''}`} onClick={() => setOpenGrid(!openGrid)}>
        <GradeIcon iconType='grid' />
      </span>
      <div className='icon-action' ref={actionRef}>
        <span className={`grade-header-icon ${openAction ? 'open-action' : ''}`} onClick={() => setOpenAction(!openAction)}>
          <GradeIcon iconType='down' />
        </span>
        <ul className={`${openAction ? 'open-action-drop-down' : ''}`}>
          {DROPDOWN_ACTION.map(({ label, event, url, icon }) => (
            <li
              className='align-items-center d-flex gap-1'
              key={label}
              onClick={() => {
                setOpenAction(false)
                url && navigate(url)
                event === 'finish' && canFinish && onFinishAction()
                if (event === 'openAction') setOpenRecommendation(true)
              }}
            >
              {label}
              {icon && (
                <>
                  {' '}
                  <Tooltip title={label + ' tooltip'} color='white'>
                    {' '}
                    <GradeIcon iconType={icon} />
                  </Tooltip>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DropDownActions
