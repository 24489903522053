import { useForm } from 'react-hook-form'
import HookFormTextField from '../../base/textfield/HookFormTextField'
import HookFormEditor from '../../base/editor/HookFormEditor'
import { RESOURCE_LOCATION } from '../../base/upload-file/ModalUploadFile/UploadFileInterface'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { VALIDATION_FILED } from 'src/constants'

interface SubmitFormProps {
  item: any
  onSubmit: (data: any) => void
  onCancel: () => void
  isComment: boolean
}

const SubmitForm = ({ item, onSubmit, onCancel, isComment }: SubmitFormProps) => {
  const validationSchema = z.object({
    comment: isComment
      ? z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED)
      : z.string().optional(),
    grade: !isComment
      ? z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED)
      : z.string().optional(),
  })

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  return (
    <div
      className='grade-box'
      key={item.begin + item.end}
      data-begin={item.begin}
      data-end={item.end}
    >
      {isComment && (
        <div className='sapp-mb-24px'>
          <HookFormEditor
            height={300}
            placeholder=''
            label='Comment:'
            name='comment'
            control={control}
            className='w-100 fs-6'
            math={false}
            defaultValue={item.comment}
            resourceLocation={RESOURCE_LOCATION.QUIZ}
            object_id={null}
            setDataFile={() => {}}
            required={isComment}
            isGradeForm={true}
          />
        </div>
      )}
      <div className='sapp-mb-24px'>
        <HookFormTextField
          required={!isComment}
          control={control}
          name='grade'
          type='number'
          placeholder=''
          label='Grade:'
          isListScreen
          defaultValue={item.grade}
          minNumber={1}
          requiredZero={true}
        />
      </div>
      <SAPPDialogButtonsCancelSubmit
        cancelClick={onCancel}
        cancelButtonCaption='Cancel'
        okButtonCaption='Save'
        okOnClick={() => {
          handleSubmit(onSubmit)()
        }}
        className='justify-content-end d-flex m-0'
      />
    </div>
  )
}

export default SubmitForm
