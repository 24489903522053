import { uniqueId } from 'lodash'
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { MY_COURSES } from 'src/constants/lang'
import "./index.scss"
import EditorReader from '../base/editor/EditorReader'
import { replaceTextAlignCenterToWebKitCenter } from 'src/utils'
import { SappTitleSolution } from '../base/SappTitleSolution'
import { Question } from 'src/type/grade'

interface IProps {
  data: Question
  defaultAnswer?: string[];
  corrects?: {
    id: string
    answer: string
    is_correct: boolean
    answer_position: number
  }[]
  solution?: string
}
interface Answer {
  id: string;
  answer: string;
  answer_position: number;
  is_correct: boolean;
}

interface Data {
  question_content: string;
  is_self_reflection: boolean;
  answers: Answer[];
}

const SelectWord = forwardRef(
  (
    {
      data,
      defaultAnswer,
      corrects,
      solution,
    }: IProps,
  ) => {
    const [questionContent, setQuestionContent] = useState<Document>()
    const [answerContent, setAnswerContent] = useState<Document>()
    const str = data?.question_content
    const [key, setKey] = useState<string>(uniqueId('key'))
    const isSelfReflection = data?.is_self_reflection

    const formatAnswer = (data: Data) => {
      const objAnswer: Record<number, { label: string; value: string; result: boolean }[]> = {};
      for (let e of data?.answers) {
        if (!objAnswer?.[e?.answer_position]) {
          objAnswer[e?.answer_position] = []
        }
        objAnswer[e?.answer_position].push({
          label: e?.answer,
          value: e?.id,
          result: e?.is_correct,
        })
      }
      return objAnswer
    }
    const answerObj = formatAnswer(data)

    const parser = new DOMParser()

    useEffect(() => {
      const doc = parser?.parseFromString(str, 'text/html')
      const elements = doc?.querySelectorAll('.question-content-tag')
      const doc2 = parser?.parseFromString(str, 'text/html')
      const elementCorrects = doc2?.querySelectorAll('.question-content-tag')

      elements.forEach((element, index) => {
        const selectElement = document?.createElement('select')
        selectElement.classList?.add('sapp-select--selectword-preview')
        selectElement.setAttribute('required', 'true')
        selectElement.id = element?.id

        const defaultAnswerValue = defaultAnswer?.[index] || ''
        let optionClass = ''
        if (Array.isArray(corrects) && corrects.length > 0) {
          const isCorrect = corrects?.some(
            (correct) =>
              correct?.answer_position === index + 1 &&
              correct?.id === defaultAnswerValue &&
              correct?.is_correct,
          )
          optionClass =
            isCorrect || isSelfReflection === true
              ? 'sapp-border-success'
              : 'sapp-border-danger'
          const textClass = isCorrect
            ? 'text-state-success'
            : 'text-state-error'
          selectElement?.classList?.add(optionClass)
          selectElement?.classList?.add('sapp-select-confirmed')
          selectElement?.classList?.add(textClass)
          selectElement?.setAttribute('disabled', 'true')
          selectElement.innerHTML = `
        <option value="" disabled selected ></option>
        ${answerObj?.[+index + 1]?.map((e) => {
            const isSelected = e?.value === defaultAnswerValue

            return `<option value="${e?.value}" ${isSelected ? 'selected' : ''
              } >${e?.label}</option>`
          })}
      `
        } else {
          selectElement.innerHTML = `
          <option value="" disabled selected >Choose</option>
          ${answerObj?.[+index + 1]?.map((e) => {
            const isSelected = e?.value === defaultAnswerValue
            if (e?.label?.length > 100) {
              let arr = []
              var start = 0 // Vị trí bắt đầu của phần tử
              var end = 0 // Vị trí kết thúc của phần tử
              while (start < e?.label?.length) {
                // Lặp cho đến khi hết chuỗi
                end = start + 50 // Tính vị trí kết thúc theo số lượng ký tự tối thiểu
                if (end < e?.label?.length) {
                  // Nếu vị trí kết thúc không vượt quá độ dài chuỗi
                  while (e?.label?.[end] !== ' ') {
                    // Lặp cho đến khi tìm được khoảng trắng
                    end-- // Giảm vị trí kết thúc
                  }
                }
                var sub = e?.label?.slice(start, end)
                // Cắt một phần tử từ vị trí bắt đầu đến vị trí kết thúc
                arr?.push(sub) // Thêm phần tử vào mảng
                start = end + 1 // Cập nhật vị trí bắt đầu của phần tử tiếp theo
              }
              return arr
                ?.map((el, i) => {
                  if (i === 0) {
                    return `<option value="${e?.value}" ${isSelected ? 'selected' : ''
                      } class="w-[50px] break-all">${el}</option>`
                  }
                  return `<option disabled value="${e?.value}" ${isSelected ? 'selected' : ''
                    } class="w-[50px] break-all">${el}</option>`
                })
                ?.join('')

              // return arr
            } else {
              return `<option value="${e?.value}" ${isSelected ? 'selected' : ''
                } class="w-[50px] break-all">${e?.label}</option>
              `
            }
          })}
          `
        }

        element.replaceWith(selectElement)
      })
      if (Array.isArray(corrects) && corrects.length > 0) {
        elementCorrects.forEach((element, index) => {
          const inputId = element?.id
          const inputValue = defaultAnswer?.[index] || ''

          let inputClass
          // if (corrects) {
          const correctAnswer = corrects?.find(
            (ans) => ans?.answer_position === index + 1 && ans?.is_correct,
          )
          if (correctAnswer) {
            inputClass = 'text-base fw-bold text-state-success'
            // }

            element.outerHTML = `
                <span>
                <span id="${inputId}" class = "${inputClass}">${correctAnswer?.answer} <span/>
                </span>
                `
          }
        })
        setAnswerContent(doc2)
      }

      setQuestionContent(doc)
    }, [defaultAnswer])

    return (
      <div>
        <EditorReader
          key={key}
          className="sapp-questions fillText"
          // style={{borderBottom: '1px solid  white'}}
          text_editor_content={
            questionContent?.documentElement?.querySelector('body')
              ?.innerHTML || ''
          }
          id="hightlight_area"          
        />
        {answerContent && (
          <>
            <h3 className="fw-bold">
              Correct Answer
            </h3>
            <EditorReader
              text_editor_content={replaceTextAlignCenterToWebKitCenter(answerContent?.documentElement.querySelector('body')?.innerHTML || '')
              }
            />
          </>
        )}
      </div>
    )
  },
)
SelectWord.displayName = 'SelectWord'
export default SelectWord
