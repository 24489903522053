import { useRef } from 'react'
import { ResizableComponent } from 'src/components/certificate/ResizableComponent'
import { Dispatch, SetStateAction } from 'react'
import './solution.scss'
import { ReactNode } from 'react'

interface IProps {
  open: string | null
  setOpen: Dispatch<SetStateAction<string | null>>
  componentPosition: object
  setComponentPosition: Dispatch<SetStateAction<object>>
  children?: ReactNode
}

const Solution = ({ open, setOpen, componentPosition, setComponentPosition, children }: IProps) => {
  const solutionContentRef = useRef<HTMLDivElement>(null)

  const handleResize = (data: { top: number; left: number; width: number; height: number }) => {
    setComponentPosition(data)
  }

  return (
    <>
      {open && (
        <div className='grade-solution-popup' ref={solutionContentRef}>
          <ResizableComponent
            index={1}
            getResize={handleResize}
            parentContentRef={solutionContentRef}
            position={componentPosition}
          >
            <div className='solution-content position-absolute top-0 start-0 w-100 h-100 bg-white'>
              <div className='mb-5 d-flex align-items-center justify-content-between'>
                <span className='solution-content-title'>Solution</span>
                <div
                  onClick={() => {
                    setOpen(null)
                  }}
                  className='cursor-pointer'
                >
                  <i className={`ki-outline ki-cross fs-1`} />
                </div>
              </div>
              <>{children}</>
            </div>
          </ResizableComponent>
        </div>
      )}
    </>
  )
}

export default Solution
