import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GradeAPI } from 'src/apis/grade'
import QuestionDetails from 'src/components/grade/QuestionDetails'
import withAuthComponents from 'src/components/auth/with-auth-components'
import FinishAlertModal from 'src/components/grade/attempt-info/FinishAlertModal'
import Recommendation from 'src/components/grade/recommendation/Recommendation'
import { LANG_GRADE_PAGE } from 'src/constants/lang'
import { IAttemptAnswerData, IQuizAttemptInfo } from 'src/type/grade'
import './Grade.scss'
import AttemptInfo from 'src/components/grade/attempt-info/AttemptInfo'
import { DEFAULT_ATTEMPT_DATA } from 'src/constants/grade'
import '@fortune-sheet/react/dist/index.css'

const Grade = () => {
  const { gradeId } = useParams()
  const [quizAttemptInfo, setQuizAttemptInfo] = useState<IQuizAttemptInfo>({
    gradeId: '',
    quizName: '',
    multipleChoiceScores: 0,
    essayScores: 0,
    comment: '',
    recommendation: '',
  })
  const [essayScores, setEssayScores] = useState<number>(0)
  const [attemptAnswerData, setAttemptAnswerData] =
    useState<IAttemptAnswerData>(DEFAULT_ATTEMPT_DATA)
  const [openRecommendation, setOpenRecommendation] = useState<boolean>(false)
  const [componentPosition, setComponentPosition] = useState<object>({
    top: 100,
    left: '35%',
    width: 565,
    height: 500,
  })
  const attemptInfoRef = useRef<HTMLDivElement | null>(null)
  const [attemptInfoHeight, setAttemptInfoHeight] = useState<number>(0)

  const handleEssayScoresChange = (value: number) => {
    setEssayScores(value)
  }

  const getQuizAttemptInfo = async () => {
    try {
      const res = await GradeAPI.getQuizAttemptInfo(gradeId || '')
      if (res.success) {
        const result = {
          gradeId: res?.data?.id,
          quizName: res?.data?.title,
          multipleChoiceScores: res?.data?.multiple_choice_grade,
          essayScores: res?.data?.essay_grade,
          comment: res?.data?.comment,
          recommendation: res?.data?.recommendation,
        }
        setQuizAttemptInfo(result)
        setEssayScores(res.data.essay_grade)

        return result
      }
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  useLayoutEffect(() => {
    const getAPIData = async () => {
      await getQuizAttemptInfo()
    }
    getAPIData()
  }, [])

  useEffect(() => {
    const getAttemptInfoHeight = () => {
      if (attemptInfoRef.current) {
        setAttemptInfoHeight(attemptInfoRef.current.offsetHeight)
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      getAttemptInfoHeight()
    })

    if (attemptInfoRef.current) {
      resizeObserver.observe(attemptInfoRef.current)
      getAttemptInfoHeight()
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div className='grade-page grade-bg-1 d-flex flex-column min-vh-100 vh-100 position-relative overflow-hidden'>
      <div ref={attemptInfoRef}>
        <AttemptInfo
          quizInfo={quizAttemptInfo}
          essayScores={essayScores}
          attemptAnswerData={attemptAnswerData}
          handleChangeAttemptData={setAttemptAnswerData}
          setOpenRecommendation={setOpenRecommendation}
        />
      </div>
      <div className='flex-grow-1 overflow-y-auto'>
        {attemptAnswerData.id && attemptAnswerData.is_present ? (
          <QuestionDetails
            idQuestion={attemptAnswerData?.attempt_answers}
            questionNumber={attemptAnswerData?.index}
            attemptInfoHeight={attemptInfoHeight}
            questionGradingId={attemptAnswerData?.question_id}
            getQuizAttemptInfo={getQuizAttemptInfo}
          />
        ) : (
          <div className='no-question'>{LANG_GRADE_PAGE.chooseQuestToView}</div>
        )}
      </div>
      <Recommendation
        gradeId={gradeId || ''}
        openRecomendation={openRecommendation}
        setOpenRecomendation={setOpenRecommendation}
        componentPosition={componentPosition}
        setComponentPosition={setComponentPosition}
        quizInfo={quizAttemptInfo}
      />
      <FinishAlertModal quizId={quizAttemptInfo.gradeId} />
    </div>
  )
}

export default withAuthComponents(Grade)
