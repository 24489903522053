import { Stream, StreamPlayerApi, StreamProps } from '@cloudflare/stream-react'
import { MutableRefObject, useRef } from 'react'
import './ModalVideo.scss'

interface IProp {
  options: StreamProps
  streamRef?: MutableRefObject<StreamPlayerApi | undefined>
}

const SAPPVideo = ({ options, streamRef }: IProp) => {
  const innerStreamRef = useRef<StreamPlayerApi>()

  streamRef = streamRef || innerStreamRef

  return (
    <>
      {options.src && (
        <div className={`group sapp-wrapper`}>
          <Stream
            {...options}
            key={options.src}
            src={options.src}
            streamRef={streamRef}
            controls
            responsive={false}
            className={`sapp-content`}
            autoplay={false}
          ></Stream>
        </div>
      )}
    </>
  )
}

export default SAPPVideo
