import { Dispatch, SetStateAction, useState } from 'react'
import Detail from './Detail'
import SubmitForm from './SubmitForm'
import { GradeAPI } from 'src/apis/grade'
import { removeHighlightFromDOM } from 'src/utils'

interface Grade {
  begin: number
  end: number
  comment?: string
  grade?: number | null
}

interface CommentAndGradeProps {
  listGrades: Grade[]
  setListGrades: Dispatch<SetStateAction<Grade[]>> | any
  idQuestion: string
  editingItem: any
  setEditingItem: Dispatch<SetStateAction<any>> | any
  setGrade: any
  fetchGrade: any
  activeQuestion: any
  getQuizAttemptInfo: any
}

const CommentAndGrade = ({
  listGrades,
  setListGrades,
  idQuestion,
  editingItem,
  setEditingItem,
  setGrade,
  fetchGrade,
  activeQuestion,
  getQuizAttemptInfo,
}: CommentAndGradeProps) => {
  // Hàm xử lý khi submit form
  const onSubmit = async (data: any) => {
    if (editingItem) {
      // Gọi API với giá trị từ form
      await getQuizAttemptInfo(
        data?.comment || '',
        Number(data?.grade || 1),
        editingItem.begin,
        editingItem.end
      )
      // Cập nhật lại listGrades sau khi submit
      setListGrades((prevList: any) =>
        prevList.map((grade: any) =>
          grade.begin === editingItem.begin && grade.end === editingItem.end
            ? { ...grade, comment: data?.comment || '', grade: data?.grade || 0 } // Cập nhật comment và grade mới
            : grade
        )
      )
      setEditingItem(null) // Xóa trạng thái chỉnh sửa
      fetchGrade()
      getQuizAttemptInfo()
    }
  }
  // Xử lý khi bấm nút cancel
  const onCancel = () => {
    setEditingItem(null) // Xóa trạng thái chỉnh sửa
  }

  // Xử lý khi bấm nút edit
  const editGrade = (item: Grade) => {
    setEditingItem(item) // Đặt item đang được chỉnh sửa
  }

  // Xử lý khi xóa
  const deleteGrade = (item: Grade) => {
    // setListGrades((prevList: any) =>
    //   prevList.filter((grade: any) => !(grade.begin === item.begin && grade.end === item.end))
    // )
    const gradeToDelete = listGrades.find(
      (grade) => grade.begin === item.begin && grade.end === item.end
    )
    // console.log(gradeToDelete)

    if (gradeToDelete) {
      // Xóa highlight khỏi DOM
      removeHighlightFromDOM(gradeToDelete.begin, gradeToDelete.end)

      // Cập nhật listGrades sau khi xóa
      setListGrades((prevGrades: any) =>
        prevGrades.filter((grade: any) => grade.begin !== item.begin && grade.end !== item.end)
      )
    }
  }

  return (
    <div className='comment-wrapper'>
      {listGrades.map((item) =>
        // Kiểm tra nếu item đang được chỉnh sửa
        editingItem && editingItem.begin === item.begin && editingItem.end === item.end ? (
          <SubmitForm
            key={`${item.begin}-${item.end}`}
            item={item}
            onSubmit={onSubmit}
            onCancel={onCancel}
            isComment={true}
          />
        ) : (
          <Detail
            key={`${item.begin}-${item.end}`}
            item={item}
            onEdit={() => editGrade(item)}
            onDelete={() => deleteGrade(item)}
          />
        )
      )}
    </div>
  )
}

export default CommentAndGrade
