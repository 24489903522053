import GradeIcon from 'src/components/grade/GradeIcon'
import CommentAndGrade from './CommenAndGrade/CommentAndGrade'
import { useEffect, useState } from 'react'
import { QUESTION_TYPES } from 'src/constants'
import OneChoiceQuestion from 'src/components/questionType/OneChoiceQuestion'
import MultiChoiceQuestion from 'src/components/questionType/MultipleChoiceQuestion'
import MatchingQuestion from 'src/components/questionType/MatchingQuestion'
import DragNDropPreivew from 'src/components/questionType/DragNDropPreview'
import SelectWord from 'src/components/questionType/SelectWordQuestion'
import AddWordPreview from 'src/components/questionType/FillText'
import EssayQuestionPreview from 'src/components/questionType/ConstructedQuestion'
import { useForm } from 'react-hook-form'
import { GradeAPI } from 'src/apis/grade'
import { replaceTextAlignCenterToWebKitCenter, secondsToMinutesAndSeconds } from 'src/utils'
import { Answer, QuestionAnswer } from 'src/type/grade'
import { Skeleton } from 'antd'
import EditorReader from '../base/editor/EditorReader'
import Solution from './solution/Solution'
interface QuestionDetailsProps {
  idQuestion: string[]
  questionNumber: number
  attemptInfoHeight: number
  questionGradingId: string
  getQuizAttemptInfo: any
}

interface Grade {
  begin: number
  end: number
  comment?: string
  grade: number | null | undefined
}

interface IFormValues {
  answer: string
}

const QuestionDetails = ({
  idQuestion,
  questionNumber,
  attemptInfoHeight,
  questionGradingId,
  getQuizAttemptInfo,
}: QuestionDetailsProps) => {
  const [gradeValue, setGradeValue] = useState<any>([])
  const [listGrades, setListGrades] = useState<Grade[]>([])
  const [isComment, setIsComment] = useState<boolean>(true)
  const [activeQuestion, setActiveQuestion] = useState<QuestionAnswer[]>([])
  const [questionCache, setQuestionCache] = useState<{ [key: string]: QuestionAnswer | null }>({})
  const [loading, setLoading] = useState<boolean>(true)
  const { control: controlAnswer, setValue } = useForm<IFormValues>()
  const [openSolutionId, setOpenSolutionId] = useState<string | null>(null)
  const [grade, setGrade] = useState<number | null>(0)
  const [componentPosition, setComponentPosition] = useState<object>({
    top: 100,
    left: '35%',
    width: 624,
    height: 224,
  })
  const [activeRequirement, setActiveRequirement] = useState<number>(0)
  const [editingItem, setEditingItem] = useState<Grade | null>(null) // Trạng thái để lưu item đang được chỉnh sửa
  const [highlights, setHighlights] = useState<any>('') // Lưu các highlight\

  // console.log('activeQuestion', activeQuestion)

  const fetchActiveQuestion = async () => {
    setLoading(true)

    // Lọc ra các câu hỏi chưa có trong cache
    const idsToFetch = idQuestion.filter((questionId) => !questionCache[questionId])

    // Nếu tất cả các câu hỏi đều có trong cache, chỉ cần lấy từ cache
    if (idsToFetch.length === 0) {
      const questionsFromCache = idQuestion
        .map((questionId) => questionCache[questionId])
        .filter((question) => question !== null) as QuestionAnswer[]
      setActiveQuestion(questionsFromCache) // Đặt tất cả câu hỏi theo thứ tự trong mảng
      setTimeout(() => {
        setLoading(false)
      }, 500)
      return
    }

    try {
      // Gọi API cho tất cả các câu hỏi chưa có trong cache
      const responses = await Promise.all(
        idQuestion.map(async (questionId) => {
          if (!questionCache[questionId]) {
            const res = await GradeAPI.getQuizAttemptGradingsAnswer(questionId)
            return { id: questionId, data: res.data.answer }
          } else {
            return { id: questionId, data: questionCache[questionId] }
          }
        })
      )

      // Cập nhật cache và set dữ liệu cho activeQuestion
      const newCache = { ...questionCache }
      responses.forEach((response) => {
        newCache[response?.id] = response.data
      })
      setQuestionCache(newCache)

      // Sắp xếp lại mảng activeQuestion theo thứ tự idQuestion
      const activeQuestions = idQuestion.map((questionId) => newCache[questionId])

      // Cập nhật state activeQuestion với mảng câu hỏi
      setActiveQuestion(activeQuestions.filter((question) => question !== null) as QuestionAnswer[])
    } catch (error) {
      console.error('Lỗi khi gọi API cho các câu hỏi:', error)
      setActiveQuestion([])
    } finally {
      setLoading(false)
    }
  }

  const fetchGrade = async () => {
    try {
      const response = await GradeAPI.getQuestionGrading(questionGradingId)
      if (response) {
        setGrade(response?.data?.question_grade)
        setListGrades(response?.data?.answer_gradings?.[0]?.details || [])
        setGradeValue(response?.data?.answer_gradings || [])
      }
    } catch (error) {
      console.error('Error fetching grade:', error)
    }
  }

  // Function to handle requirement clicks and set active
  const handleRequirementClick = (index: number) => {
    setActiveRequirement(index)
  }

  useEffect(() => {
    fetchActiveQuestion()
    fetchGrade()
    setActiveRequirement(0)
    setHighlights('')
  }, [idQuestion])

  const handleGenarateSelectAnswer = (
    defaultValue: { answer_position: number; answer_id: string }[]
  ): string[] => {
    const newArr: string[] = []
    if (defaultValue?.length > 0) {
      const sorted = defaultValue.sort(
        (
          a: { answer_position: number; answer_id: string },
          b: { answer_position: number; answer_id: string }
        ) => a.answer_position - b.answer_position
      )
      const obj: { [key: number]: string } = {}
      for (const e of sorted) {
        obj[e.answer_position - 1] = e.answer_id
      }
      for (let i = 0; i < sorted[sorted.length - 1].answer_position; i++) {
        newArr.push(obj[i])
      }
    }

    return newArr
  }

  function createArrayWithValues(
    myAnswerArray: {
      answer_id: string
    }[],
    answers: {
      id: string
      answer: string
      answer_position: number
    }[]
  ) {
    const resultArray = []
    if (myAnswerArray.length > 0) {
      for (const el of myAnswerArray) {
        const item = answers.find((e) => e.id === el.answer_id)
        if (item) {
          resultArray.push({
            id: item.id,
            value: item.answer,
            idAnswer: el.answer_id,
          })
        } else {
          resultArray.push({
            id: '',
            value: '',
            idAnswer: '',
          })
        }
      }
    }
    return resultArray
  }

  interface IAnswer {
    id: string
    answer: string
    answer_position: number
    question_id: string
    is_correct: boolean
  }

  function getCorrect(answers: IAnswer[], questionType: QUESTION_TYPES) {
    switch (questionType) {
      case QUESTION_TYPES.ONE_CHOICE:
      case QUESTION_TYPES.TRUE_FALSE:
        return Object.fromEntries(answers.map((answer) => [answer.id, answer.is_correct]))
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        return Object.fromEntries(
          (answers || []).map((originalAnswer) => [originalAnswer.id, originalAnswer.is_correct])
        )
      case QUESTION_TYPES.FILL_WORD:
      case QUESTION_TYPES.SELECT_WORD:
      case QUESTION_TYPES.MATCHING:
      case QUESTION_TYPES.DRAG_DROP:
        return answers || []
      default:
        return []
    }
  }

  // console.log('listGrades', listGrades)

  const renderQuestions = (questionData: QuestionAnswer, index: number) => {
    if (loading) {
      return (
        <>
          <Skeleton active paragraph={{ rows: 16 }} />
        </>
      )
    }

    if (!activeQuestion) {
      return <div>Không có dữ liệu câu hỏi</div>
    }

    const question = questionData?.question
    const answer = questionData?.answer || []
    if (!question) {
      return <div>Không có dữ liệu câu hỏi</div>
    }

    return (
      <div>
        {question?.question_topic?.description && question.qType !== QUESTION_TYPES.ESSAY && (
          <EditorReader text_editor_content={question?.question_topic?.description} />
        )}
        {(() => {
          switch (question?.qType) {
            case QUESTION_TYPES.ONE_CHOICE:
            case QUESTION_TYPES.TRUE_FALSE:
              return (
                <OneChoiceQuestion
                  data={question}
                  control={controlAnswer}
                  corrects={
                    getCorrect(question?.answers, question?.qType) as { [key: string]: boolean }
                  }
                  setValue={setValue}
                  name={`${questionData?.id}`}
                  solution={question?.solution}
                  defaultValues={questionData?.question_answer_id}
                />
              )
            case QUESTION_TYPES.MULTIPLE_CHOICE:
              return (
                <MultiChoiceQuestion
                  data={question}
                  control={controlAnswer}
                  corrects={
                    getCorrect(question?.answers, question?.qType) as { [key: string]: boolean }
                  }
                  setValue={setValue}
                  name={`${questionData?.id}`}
                  solution={question?.solution}
                  defaultValues={questionData?.answer?.map((e: Answer) => e?.answer_id)}
                />
              )
            case QUESTION_TYPES.MATCHING:
              return (
                <MatchingQuestion
                  data={question}
                  defaultAnswer={questionData?.answer}
                  corrects={questionData?.answer_matching_mapping}
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.DRAG_DROP:
              return (
                <DragNDropPreivew
                  data={question}
                  defaultAnswer={createArrayWithValues(answer, question?.answers)}
                  corrects={getCorrect(question?.answers || [], question?.qType) as IAnswer[]}
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.SELECT_WORD:
              return (
                <SelectWord
                  data={question}
                  defaultAnswer={handleGenarateSelectAnswer(questionData?.answer)}
                  corrects={
                    getCorrect(question?.answers || [], question?.qType) as {
                      id: string
                      answer: string
                      is_correct: boolean
                      answer_position: number
                    }[]
                  }
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.FILL_WORD:
              return (
                <AddWordPreview
                  data={question}
                  defaultAnswer={answer
                    .sort((a: Answer, b: Answer) => a?.answer_position - b?.answer_position)
                    .map((e: Answer) => e.answer_text)}
                  corrects={
                    getCorrect(question?.answers || [], question?.qType) as {
                      id: string
                      answer: string
                      is_correct: boolean
                      answer_position: number
                    }[]
                  }
                  solution={question?.solution}
                />
              )
            case QUESTION_TYPES.ESSAY:
              return (
                <EssayQuestionPreview
                  data={question}
                  control={controlAnswer}
                  defaultValue={questionData?.short_answer || ''}
                  name={`${questionData?.id}`}
                  text_editor_content={questionData?.question?.question_content || ''}
                  index={index}
                  listGrades={listGrades}
                  setListGrades={setListGrades}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  highlights={highlights}
                  setHighlights={setHighlights}
                />
              )
            default:
              return <div>Loại câu hỏi không hợp lệ</div>
          }
        })()}
      </div>
    )
  }
  return (
    <div
      className={
        activeQuestion && activeQuestion[0]?.question.qType === QUESTION_TYPES.ESSAY ? 'd-flex' : ''
      }
    >
      <div
        className={`${
          activeQuestion && activeQuestion[0]?.question.qType === QUESTION_TYPES.ESSAY
            ? 'column-left-essay'
            : 'w-100'
        }`}
      >
        {activeQuestion.map((questionData, index: number) => (
          <div
            className={`grade-requirement grade-px-24 ${
              activeQuestion && questionData?.question.qType === QUESTION_TYPES.ESSAY
                ? 'flex-grow-1'
                : ''
            }
                ${index === activeRequirement ? 'active' : 'inactive'}
            `}
            onClick={index !== activeRequirement ? () => handleRequirementClick(index) : undefined}
            key={questionData?.id}
          >
            <div className='question-wrapper'>
              <div className='question-header'>
                <Skeleton active loading={loading}>
                  <div>
                    <span className='question'>
                      <strong>
                        Question {questionNumber}{' '}
                        {questionData?.question?.requirements?.length > 0 &&
                        questionData?.question?.qType === QUESTION_TYPES.ESSAY
                          ? ` - Requirement ${index + 1} `
                          : ''}
                        :
                      </strong>
                      <span
                        className={
                          activeQuestion &&
                          (questionData?.is_correct ||
                            questionData?.question?.qType === QUESTION_TYPES.ESSAY)
                            ? ''
                            : 'incorrect'
                        }
                      >
                        {questionData?.question?.qType !== QUESTION_TYPES.ESSAY ? (
                          <>{questionData?.is_correct ? 'Correct' : 'Incorrect'}</>
                        ) : (
                          <>{questionData?.active === 'SUBMITED' ? 'Submitted' : 'Unsubmitted'}</>
                        )}
                      </span>
                    </span>
                    {questionData?.question.qType !== QUESTION_TYPES.ESSAY && (
                      <span className='percentage'>
                        <GradeIcon iconType='globe' />
                        <span>{questionData?.question?.question_report?.ratio}%</span>
                      </span>
                    )}
                    <span>
                      Time Spent: {secondsToMinutesAndSeconds(questionData?.time_spent ?? 0)}
                    </span>
                  </div>
                  <div className='grade d-flex align-items-center'>
                    {questionData?.question.qType === QUESTION_TYPES.ESSAY && !loading && (
                      <>
                        <div
                          className='view-solution sapp-cursor-pointer percentage'
                          onClick={() => setOpenSolutionId(questionData?.id)}
                        >
                          View Solution
                        </div>
                      </>
                    )}
                    <div>
                      Grade: <strong>{grade || 0}</strong>
                    </div>
                  </div>
                </Skeleton>
              </div>
              <div className='question-content'>{renderQuestions(questionData, index)}</div>
            </div>
            {openSolutionId === questionData?.id &&
              questionData?.question.qType === QUESTION_TYPES.ESSAY && (
                <Solution
                  key={questionData?.id}
                  open={openSolutionId}
                  setOpen={setOpenSolutionId}
                  componentPosition={componentPosition}
                  setComponentPosition={setComponentPosition}
                >
                  <EditorReader
                    text_editor_content={replaceTextAlignCenterToWebKitCenter(
                      questionData?.question?.solution || ''
                    )}
                    className=''
                  />
                </Solution>
              )}
          </div>
        ))}
      </div>
      {activeQuestion[0]?.question.qType === QUESTION_TYPES.ESSAY && !loading && (
        <div className='question-comment'>
          <CommentAndGrade
            listGrades={listGrades}
            setListGrades={setListGrades}
            idQuestion={questionGradingId}
            editingItem={editingItem}
            setEditingItem={setEditingItem}
            setGrade={setGrade}
            fetchGrade={fetchGrade}
            activeQuestion={activeQuestion}
            getQuizAttemptInfo={getQuizAttemptInfo}
          ></CommentAndGrade>
        </div>
      )}
    </div>
  )
}

export default QuestionDetails
