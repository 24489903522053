import { differenceInMinutes, differenceInSeconds, format } from 'date-fns'
import { AppConfig } from 'src/constants'
import {
  deserializeHighlights,
  doHighlight,
  optionsImpl,
  removeHighlights,
  serializeHighlights,
  createWrapper,
  highlightRange,
} from '../../node_modules/@funktechno/texthighlighter/lib/index'
import 'src/global.d.ts'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'
import { IParticipantInTest } from 'src/type/entrancetest'

export const getActToken = (): string => {
  return localStorage.getItem('actToken') || ''
}

export const getRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') || ''
}

export const setActToken = (accToken: string) => {
  localStorage.setItem('actToken', accToken)
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refreshToken', refreshToken)
}

export const removeJwtToken = () => {
  localStorage.removeItem('actToken')
  localStorage.removeItem('refreshToken')
}

export function getFileSizeMB(size: number, fractionDigits?: number | undefined): number {
  if (size <= 0) return 0
  if (fractionDigits === undefined || fractionDigits < 0) fractionDigits = 0
  return Number(parseFloat(`${size / (1024 * 1024)}`).toFixed(fractionDigits))
}
/**
 * @description Hiện thị bytes sang dạng khác gọn hơn
 *
 * @param {number} bytes
 * @return {*}
 */
export const displayBytes = (bytes?: number | null) => {
  if (!bytes) {
    return '-'
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 KB'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const result = (bytes / Math.pow(1024, i)).toFixed(2)
  return result + ' ' + sizes[i]
}

export function checkTypeFile(type: string) {
  return AppConfig.imageFileTypeWhiteList.includes(type)
}

export const isValidHttpUrl = (string: string | URL) => {
  let url: URL
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const validatePassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/

export const phoneRegExp = /^(0\d{9}|0\d{10})$/

export const getUppercaseByNumber = (num: number): string => {
  let result = ''
  while (num > 0) {
    let remainder = num % 26
    if (remainder === 0) {
      remainder = 26
      num--
    }
    let char = String.fromCharCode(remainder + 64)
    result = char + result
    num = Math.floor(num / 26)
  }
  return result
}

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
export const fileExtension = '.xlsx'

export const usernamePattern = /^[A-Za-z0-9](?:[A-Za-z0-9_.-]*[A-Za-z0-9])?$/

export function secondsToMinutesAndSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Format the result as "minutes:seconds"
  const formattedTime = `${minutes > 9 ? '' : '0'}${minutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`

  return formattedTime
}

export const formatDate = (date: Date | null) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : null
}

export const htmlToRaw = (html: string): string => {
  if (!html) {
    return ''
  }
  let result = ''
  let inTag = false
  for (let i = 0; i < html.length; i++) {
    let char = html[i]
    if (char === '<') {
      inTag = true
    } else if (char === '>') {
      inTag = false
    } else if (!inTag) {
      result += char
    }
  }
  result = result.replace(/&/g, '&')
  result = result.replace(/</g, '<')
  result = result.replace(/>/g, '>')
  result = result.replace(/"/g, '"')
  result = result.replace(/'/g, "'")
  return decodeHtml(result)?.trim()
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const cleanParamsAPI = (params: Object) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== null && value !== '')
  )
}

export const removeDuplicateById = <T extends { dom_id: any }>(array: T[], id: keyof T): T[] => {
  const seen = new Set()
  return array.filter((item) => {
    if (seen.has(item[id])) {
      return false
    } else {
      seen.add(item[id])
      return true
    }
  })
}

export const sizeInBytes = (megabytes: number) => {
  return megabytes * 1024 * 1024
}

export const getCourseFullLevel = () => {
  return window.localStorage.getItem('fullLevel') === 'true' ? true : false
}

export const setCourseLevel = (level: string) => {
  localStorage.setItem('fullLevel', level)
}

export const formatISOFromDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 0, 0, 0)
}

export const formatISOToDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 23, 59, 59, 999)
}

export function getDateInfo(inputDate: Date) {
  const date = new Date(inputDate)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return {
    day,
    month,
    year,
  }
}

export const bytesToGB = (bytes: number) => {
  const gigabytes = bytes / (1024 * 1024 * 1024)
  return gigabytes.toFixed(2) // Round to 2 decimal places
}

export const convertItemSelect = (items: Array<any> | undefined) => {
  return items && items?.map((item: any) => ({ label: item?.name, value: item?.id }))
}

export const convertDateStringDayMonthYear = (dateString: string) => {
  // Tạo đối tượng Date từ chuỗi ngày tháng
  const dateObject = new Date(dateString)

  // Lấy ngày, tháng, năm từ đối tượng Date
  const year = dateObject.getFullYear()
  const month = `0${dateObject.getMonth() + 1}`.slice(-2) // Tháng bắt đầu từ 0
  const day = `0${dateObject.getDate()}`.slice(-2)

  // Tạo chuỗi định dạng mới
  const isoDateString = `${year}-${month}-${day}`
  return isoDateString
}

export const convertSnakeCaseToTitleCase = (snakeCaseString: string) => {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const convertMathToImage = async (element: any) => {
  const viewer = com?.wiris?.js?.JsPluginViewer
  if (viewer && element) {
    try {
      await viewer.parseElement(element, true, function () {})
    } catch (error) {
      console.error('Error:', error)
    }
  }
}

export function cleanObject(obj: any) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === 'undefined' ||
      obj[propName] === 'null'
    ) {
      delete obj[propName]
    } else if (typeof obj[propName] === 'object') {
      cleanObject(obj[propName])
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName]
      }
    }
  }
  return obj
}

export function runHighlight(
  handleSaveHighLight: any,
  allowHighLight: boolean = true,
  elementID = 'highlight_area',
  options?: optionsImpl
) {
  const domEle = document.getElementById(elementID)

  if (domEle && allowHighLight) {
    const selection = window?.getSelection()

    if (selection && selection?.rangeCount > 0) {
      const range = selection?.getRangeAt(0) // Lấy vùng đã được bôi đen

      // Tìm các highlight hiện có trong đoạn này
      const existingHighlights = Array.from(domEle.querySelectorAll('span.render-highlight'))

      let isWrapperRequired = false

      existingHighlights.forEach((highlight) => {
        const highlightRange = document.createRange()
        highlightRange.selectNodeContents(highlight)

        // Kiểm tra nếu highlight cũ nằm trọn trong vùng mới, không xóa nếu là highlight khác loại
        if (
          range.compareBoundaryPoints(Range.START_TO_START, highlightRange) <= 0 &&
          range.compareBoundaryPoints(Range.END_TO_END, highlightRange) >= 0 &&
          highlight.classList.contains('render-highlight')
        ) {
          isWrapperRequired = true
          // Giữ nguyên các thẻ cũ có lớp highlight khác, không ghi đè
        }
      })

      // Nếu có highlight cũ bên trong đoạn chọn, thêm highlight mới mà không xóa cái cũ
      if (isWrapperRequired) {
        const highlightWrapper = createWrapper({
          highlightedClass: options?.highlightedClass || 'render-highlight',
          color: options?.color || 'yellow',
        })

        // Tạo DocumentFragment để giữ các highlight cũ
        const fragment = document.createDocumentFragment()
        const contents = range.cloneContents() // Lấy nội dung trong vùng chọn
        fragment.appendChild(contents) // Đưa vào DocumentFragment

        // Thêm lớp highlight mới mà vẫn giữ nguyên các lớp cũ
        // highlightWrapper.appendChild(fragment);
        // range.insertNode(highlightWrapper); // Chèn lại vào DOM
        highlightRange(domEle, range, highlightWrapper)
        console.log('case 1')
      } else {
        // Nếu không cần bao bọc, chỉ thêm highlight mới
        const highlightWrapper = createWrapper({
          highlightedClass: options?.highlightedClass || 'render-highlight',
          color: options?.color || 'yellow',
        })

        try {
          // range.surroundContents(highlightWrapper); // Bao quanh toàn bộ đoạn đã chọn
          highlightRange(domEle, range, highlightWrapper)
          console.log('case 2')
        } catch (e) {
          // Fallback sang highlightRange nếu có lỗi
          console.log('case 3')
          highlightRange(domEle, range, highlightWrapper)
        }
      }

      console.log(serializeHighlights(domEle))
      // Sau khi highlight xong, lưu lại dưới dạng JSON
      handleSaveHighLight(serializeHighlights(domEle))
    }
  }
}

export function DeserializeHighlight(highlighted: any, elementID = 'highlight_area') {
  const domEle = document.getElementById(elementID)
  removeHighlights(domEle as any)
  deserializeHighlights(domEle as any, highlighted)
}

// Function để xóa highlight dựa trên begin và end
export const removeHighlightFromDOM = (begin: number, end: number) => {
  const domEle = document.getElementById('highlight_area')

  if (!domEle) return

  // Tìm tất cả các highlight (thẻ <span>)
  const highlights = Array.from(domEle.querySelectorAll('span.render-highlight'))

  highlights.forEach((highlight: any) => {
    const textContent = highlight.textContent || ''
    const highlightStart = domEle.innerText.indexOf(textContent)
    const highlightEnd = highlightStart + textContent.length

    console.log('highlightStart', highlightStart)
    console.log('highlightEnd', highlightEnd)

    // Chỉ xóa highlight nếu nó nằm hoàn toàn trong đoạn văn bản từ begin đến end
    if (highlightStart >= begin && highlightEnd <= end) {
      // Sử dụng removeHighlights từ package để xóa highlight cụ thể này
      removeHighlights(highlight)
    }
  })
}

// Hàm add style vào trang
export const addStyle = () => {
  var css = `
  .__comment {
    background: rgb(255 0 0 / 50%);
  }

  .__grade {
    color: red;
  }
  `,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style')

  head.appendChild(style)
  style.appendChild(document.createTextNode(css))
}

// Hàm để gán mỗi ký tự với thẻ span và gán id
const PREFIX = 'h_'

export function indexCharacters(contentSelector: string) {
  let index = 0

  function walk(node: any) {
    if (node.nodeType === Node.TEXT_NODE) {
      const characters = node.nodeValue.split('')
      const fragment = document.createDocumentFragment()

      characters.forEach((char: string) => {
        const span = document.createElement('span')
        span.textContent = char
        span.setAttribute('id', `${PREFIX}${index++}`)
        fragment.appendChild(span)
      })

      return node.parentNode.replaceChild(fragment, node)
    }

    for (let child of node.childNodes) {
      if (!child.id?.startsWith(PREFIX)) {
        walk(child)
      }
    }
  }

  const content = document.querySelector(contentSelector)
  if (content) walk(content)
}

// Hàm để lấy các node được chọn
export function getSelectedElements() {
  const selection = window.getSelection()
  if (selection?.type !== 'Range') return []

  const range = selection.getRangeAt(0)
  const selectedNodes: any[] = []
  const walker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT, {
    acceptNode(node) {
      return NodeFilter.FILTER_ACCEPT
    },
  })

  while (walker.nextNode()) {
    const node = walker.currentNode
    if (range.intersectsNode(node)) {
      selectedNodes.push(node)
    }
  }
  return selectedNodes.length ? selectedNodes : [selection.anchorNode?.parentNode]
}

// Hàm để thêm highlight cho các phần tử được chọn
export function highlightSelection(className: string) {
  const selectedNodes = getSelectedElements()

  if (!selectedNodes.length) return null

  // Lấy thẻ <span> đầu tiên và cuối cùng trong vùng chọn
  const firstSpan = selectedNodes[0] // Thẻ đầu tiên
  const lastSpan = selectedNodes[selectedNodes.length - 1] // Thẻ cuối cùng

  // Lấy id của thẻ <span> đầu tiên và cuối cùng để làm begin và end
  const begin = parseInt(firstSpan.id.replace(PREFIX, ''), 10) // Lấy vị trí begin từ id
  const end = parseInt(lastSpan.id.replace(PREFIX, ''), 10) + 1 // Lấy vị trí end từ id

  selectedNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
      const span = document.createElement('span')
      span.textContent = node.textContent
      node.parentNode.replaceChild(span, node)
    }
    node.classList.add(className)
  })

  return { begin, end } // Trả về vị trí begin và end
}

// Hàm thay thế style text-align: center thành style text-align: -webkit-center trong chuỗi HTML
export const replaceTextAlignCenterToWebKitCenter = (htmlString: string) => {
  // Sử dụng biểu thức chính quy để thay thế
  return htmlString.replace(/text-align:\s*center/g, 'text-align: -webkit-center')
}
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const useGetDataQuery = (
  queryKey: string,
  params: Object,
  fetchFunction: () => Promise<any>,
  enabled?: boolean,
  onError?: ((err: unknown) => void) | undefined
) => {
  const fetchData = async () => {
    const { data } = await fetchFunction()
    return data
  }

  return useQuery([queryKey, params], fetchData, {
    enabled: enabled,
    onError: onError,
    retry: false,
  })
}

export const beforeUpload = (file: File) => {
  const isExcel =
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel'
  const isCSV = file.type === 'text/csv'
  if (!isExcel && !isCSV) {
    toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
    return false
  }
  if (file.size > 5 * 1024 * 1024) {
    toast.error('The file is too large! It must be less than or equal to 5 MB!')
    return false
  }
  return true
}

export const handleParticipantLevel = (data: IParticipantInTest, name?: string) => {
  switch (name) {
    case 'ACCA':
      return data?.detail?.acca_level ?? '--'
    case 'CFA':
      return data?.detail?.cfa ?? '--'
    case 'CMA':
      return data?.detail?.cma_level ?? '--'
    default:
      return '--'
  }
}

export const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const formattedHours = hours > 0 ? `${hours}h` : ''
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes}m` : ''

  return formattedHours + formattedMinutes || '--'
}

export const convertString = (input: string | undefined) => {
  // Tách các từ bằng dấu gạch dưới, sau đó viết hoa chữ cái đầu mỗi từ
  return (
    input
      ?.toLowerCase() // Chuyển tất cả về chữ thường
      .split('_') // Tách chuỗi dựa vào dấu gạch dưới
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Viết hoa chữ cái đầu mỗi từ
      .join(' ') || '--'
  ) // Ghép lại các từ bằng dấu cách
}

export const convertTime = (start: any, end: any) => {
  const startTime = new Date(start) as any
  const endTime = new Date(end) as any

  // Tính số giây chênh lệch
  const diffInSeconds = Math.abs(differenceInSeconds(endTime, startTime))

  // Nếu thời gian chênh lệch nhỏ hơn 1 phút, hiển thị giây
  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`
  }

  // Nếu thời gian lớn hơn 1 phút, tính số phút
  const diffInMinutes = Math.abs(differenceInMinutes(endTime, startTime))
  const remainingSeconds = diffInSeconds % 60

  if (diffInMinutes === 0) {
    return `${remainingSeconds}s` // Nếu chỉ có vài giây, không hiển thị phút
  }

  return diffInMinutes > 0 && remainingSeconds === 0
    ? `${diffInMinutes}m`
    : `${diffInMinutes}m ${remainingSeconds}s` // Hiển thị cả phút và giây nếu cần
}
