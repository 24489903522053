import { Workbook, WorkbookInstance } from '@fortune-sheet/react'
import React, { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { RESPONSE_OPTION } from 'src/type/grade'
import EditorReader from '../base/editor/EditorReader'
import {
  highlightSelection,
  indexCharacters,
  replaceTextAlignCenterToWebKitCenter,
  runHighlight,
} from 'src/utils'
import { Question } from 'src/type/grade'
import { Control } from 'react-hook-form'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'

interface IFormValues {
  answer: string
}

type IPreviewProp = {
  data: Question
  control: Control<IFormValues>
  name: string
  defaultValue?: string | null
  text_editor_content: string
  index: number
  listGrades: any
  setListGrades: any
  editingItem: any
  setEditingItem: Dispatch<SetStateAction<any>> | any
  highlights: any
  setHighlights: any
}

const EssayQuestionPreview = ({
  data,
  name,
  control,
  defaultValue,
  text_editor_content,
  index,
  listGrades,
  setListGrades,
  editingItem,
  setEditingItem,
  highlights,
  setHighlights,
}: IPreviewProp) => {
  const [openPreviewFile, setOpenPreviewFile] = useState({ status: false, url: '' })
  const [htmlOutput, setHtmlOutput] = useState<string | null>(null)

  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [selectedText, setSelectedText] = useState('')

  // console.log('selectedText', selectedText)
  // console.log('highlights', highlights)

  // Khi người dùng bôi đen văn bản
  const handleMouseUp = (e: any) => {
    if (e?.target?.tagName?.charAt(0) !== 'm' && e?.target?.firstChild?.tagName !== 'math') {
      const selection = window.getSelection()
      const selectedString = selection?.toString()

      if (selectedString) {
        const range = selection?.getRangeAt(0)
        const rect = range?.getBoundingClientRect()

        // Giả sử tooltip có width cố định hoặc bạn có thể tính toán width sau khi render
        const tooltipWidth = 150 // Giả định chiều rộng của tooltip là 150px

        // Căn giữa tooltip so với vùng bôi đen
        const leftPosition = (rect?.left ?? 0) + (rect?.width || 0) / 2 - tooltipWidth / 2

        // Đặt tooltip ở phía trên vùng bôi đen (cách phần text 10px ở phía trên)
        setTooltipPosition({
          top: (rect?.top ?? 0) - 36 + window.scrollY - 10, // Đặt tooltip phía trên text
          left: leftPosition,
        })

        // createTooltip(1, {top: (rect?.top ?? 0) - 36 + window.scrollY - 10,
        //   left: leftPosition,
        //   width: 200});

        setSelectedText(selectedString)
        setShowTooltip(true) // Hiển thị tooltip
      }
    }
  }

  // Sự kiện onMouseDown để ẩn tooltip khi bấm ra ngoài
  const handleMouseDown = () => {
    // Ẩn tooltip khi người dùng click chuột vào bất cứ đâu
    setShowTooltip(false)
  }

  const handleSaveHighLight = (highlightItem: any) => {
    // Xử lý dữ liệu highlight, ví dụ: lưu vào state hoặc gửi lên server
    // console.log('Highlight item:', highlightItem);

    // Giả sử bạn có state listGrades để lưu thông tin highlight
    setListGrades((prevGrades: any) => [...prevGrades, highlightItem])
    setEditingItem(highlightItem)
    // addHighlight(highlightItem)
  }

  const addHighlight = (e: any) => {
    setHighlights(e)
  }

  function createTooltip(grade: any, position: any) {
    // Tạo thẻ span để hiển thị tooltip
    const tooltip = document.createElement('span')
    tooltip.className = 'grade-tooltip'
    tooltip.innerText = `${grade}`

    // Thiết lập vị trí cho tooltip
    tooltip.style.position = 'absolute'
    tooltip.style.top = `${position.top}px` // Đẩy lên trên đoạn văn bản
    tooltip.style.left = `${position.left + position.width / 2 - 20}px` // Căn giữa tooltip

    // Thêm tooltip vào body hoặc bất kỳ phần tử nào bạn muốn
    document.body.appendChild(tooltip)
  }

  const handleOptionSelect = (type: 'comment' | 'grading' | 'both') => {
    let className

    // Xác định class sẽ thêm vào các ký tự dựa trên loại highlight
    if (type === 'both') {
      className = 'both-highlight'
    } else if (type === 'comment') {
      className = 'comment-highlight' // Class cho comment
    } else {
      className = 'grading-highlight' // Class cho grading
    }

    // Thêm highlight và lấy vị trí begin và end
    const highlightPosition = highlightSelection(className)

    if (highlightPosition) {
      const { begin, end } = highlightPosition // Nhận về vị trí begin và end

      // Lưu thông tin về highlight
      const highlightItem = {
        begin, // Vị trí bắt đầu của đoạn highlight
        end, // Vị trí kết thúc của đoạn highlight
        comment: '', // Loại highlight: 'comment', 'grading', hoặc 'both'
        grade: 0, // Class đã thêm vào đoạn highlight
      }

      // Gọi hàm để lưu highlight vào state hoặc gửi lên server
      handleSaveHighLight(highlightItem)
    }

    // Ẩn tooltip sau khi chọn xong loại highlight
    setShowTooltip(false)
  }

  const handleWorkbookRef = (instance: WorkbookInstance) => {
    if (instance) {
      try {
        const sheets = instance?.getAllSheets()
        const sheetData = sheets?.[0]
        const rowCount = sheetData?.data?.length ?? 0
        const columnCount = sheetData?.data?.[0]?.length ?? 0
        const range = [{ row: [0, rowCount], column: [0, columnCount] }]
        const html = instance?.getHtmlByRange(range)
        setHtmlOutput(html)
      } catch (error) {
        console.error('Error in getHtmlByRange:', error)
      }
    }
  }

  // Hàm khôi phục highlight từ listGrade khi load lần đầu tiên
  const restoreHighlights = (listGrades: any[]) => {
    ;(listGrades || []).forEach((item) => {
      const { begin, end, grade, comment } = item // Lấy thông tin từ item
      let className = ''

      // Xác định class sẽ thêm vào các ký tự dựa trên loại highlight
      if (grade && comment) {
        className = 'both-highlight'
      } else if (comment) {
        className = 'comment-highlight' // Class cho comment
      } else {
        className = 'grading-highlight' // Class cho grading
      }

      if (begin !== undefined && end !== undefined && className) {
        // Lặp qua các vị trí từ begin đến end
        for (let i = begin; i < end; i++) {
          const span = document.getElementById(`h_${i}`)

          if (span) {
            // Thêm class đã lưu cho các thẻ span
            span.classList.add(className)
          }
        }
      }
      const commentSection = document.getElementById('question-comment')
      if (commentSection) {
        // Cuộn xuống cuối phần tử với smooth scrolling
        commentSection.scrollTo({
          top: commentSection.scrollHeight,
          behavior: 'smooth',
        })
      }
    })
  }

  // // Khi lần đầu load trang
  // Sử dụng MutationObserver để theo dõi khi EditorReader render xong
  useEffect(() => {
    const editorArea = document.getElementById('editor_area')

    // Chỉ thực thi khi có DOM thay đổi
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          indexCharacters('#editor_area') // Sau khi EditorReader render xong, bọc các ký tự trong thẻ <span>
          setTimeout(() => {
            // console.log('123')
            restoreHighlights(listGrades) // Đảm bảo khôi phục sau khi indexCharacters chạy xong
          }, 100)
          observer.disconnect() // Ngắt observer sau khi render xong
          break
        }
      }
    })

    if (editorArea) {
      // Bắt đầu quan sát sự thay đổi trong DOM
      observer.observe(editorArea, { childList: true, subtree: true })
    }

    return () => {
      observer.disconnect() // Ngắt kết nối khi component unmount
    }
  }, [])

  return (
    <div className='bg-white'>
      {showTooltip && !editingItem && (
        <div
          className='tooltip-grade'
          style={{
            position: 'absolute',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
          }}
        >
          <button onClick={() => handleOptionSelect('comment')}>Comment</button>
          <button onClick={() => handleOptionSelect('grading')}>Grading</button>
          <button onClick={() => handleOptionSelect('both')}>Cả hai</button>
        </div>
      )}
      <div>
        {/*Topic content*/}
        <EditorReader
          text_editor_content={replaceTextAlignCenterToWebKitCenter(text_editor_content)}
          className='sapp-questions mb-10'
        />
        {/*Topic description*/}
        <EditorReader
          className='sapp-questions mb-4'
          text_editor_content={data?.question_topic?.description}
        />
        {/*Requirement content, and file*/}
        <div className='topic-requirement'>
          <div className='border my-6' />
          <div className='fs-4 fw-bold mb-2'>Requirement:</div>
          {data?.requirements?.length > 0 && (
            <div className='mt-4'>
              <div className='fs-4 fw-bold'>{`${data?.requirements[index]?.name}`}</div>
              {data?.requirements[index]?.description && (
                <EditorReader
                  className='sapp-questions mt-1.5'
                  text_editor_content={replaceTextAlignCenterToWebKitCenter(
                    data?.requirements[index]?.description || ''
                  )}
                />
              )}
              {data?.requirements[index]?.files?.length > 0 &&
                data?.requirements[index]?.files.map((e, index: number) => {
                  return (
                    <div
                      className='fs-4 cursor-pointer sapp-text-gray-800'
                      onClick={() => {
                        setOpenPreviewFile({ status: true, url: e?.resource?.url })
                      }}
                      key={index}
                    >
                      {e?.resource?.name}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
        {/*Topic Resource*/}
        <>
          {data.question_topic?.files?.length > 0 && (
            <div className='topic-resource'>
              <div className='border my-6' />
              <div>
                <div className='fs-4 fw-bold mb-2'>Topic Resource:</div>
                {data.question_topic?.files.map((e: any, index: number) => {
                  return (
                    <div
                      className='fs-4 cursor-pointer sapp-text-gray-800'
                      onClick={() =>
                        setOpenPreviewFile({
                          status: true,
                          url: e?.resource?.url,
                        })
                      }
                      key={index}
                    >
                      {e?.resource?.name}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>
        {
          //Question and answer file
        }
        {/*Question aswer*/}
        <div className='w-100 my-8'>
          {data?.response_option === RESPONSE_OPTION.WORD ||
          data?.response_option_answer === RESPONSE_OPTION.WORD ? (
            <div
              className=''
              id='highlight_area'
              onMouseUp={handleMouseUp}
              onMouseDown={handleMouseDown}
            >
              <EditorReader
                text_editor_content={replaceTextAlignCenterToWebKitCenter(
                  defaultValue ? defaultValue : ''
                )}
                className='grade-questions-content border p-10'
                highlighted={highlights}
                id='editor_area'
              />
            </div>
          ) : data?.response_option === RESPONSE_OPTION.SHEET ||
            data?.response_option_answer === RESPONSE_OPTION.SHEET ? (
            <React.Fragment>
              <div className='d-none'>
                <Controller
                  name={(name as 'answer') || 'answer'}
                  control={control}
                  defaultValue={defaultValue ? defaultValue : undefined}
                  render={({ field: { value } }) => {
                    let parsedData = [{ name: 'Sheet1' }]
                    try {
                      if (value) {
                        parsedData = JSON.parse(value.replace(/\\/g, ''))
                      }
                    } catch (error) {
                      console.error('Error parsing JSON:', error)
                    }
                    return <Workbook ref={handleWorkbookRef} data={parsedData} />
                  }}
                ></Controller>
              </div>
              <div
                className=''
                id='highlight_area'
                onMouseUp={(e: any) => {
                  if (
                    e?.target?.tagName?.charAt(0) !== 'm' &&
                    e?.target?.firstChild?.tagName !== 'math'
                  ) {
                    if (e) {
                      runHighlight(() => {}, true, 'highlight_area')
                    }
                  }
                }}
              >
                <EditorReader
                  text_editor_content={replaceTextAlignCenterToWebKitCenter(
                    htmlOutput ? htmlOutput : ''
                  )}
                  className='grade-questions-content table-content overflow-auto'
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      </div>
      <ModalPreviewFile
        open={openPreviewFile.status}
        setOpen={setOpenPreviewFile}
        url={openPreviewFile?.url || ''}
      />
    </div>
  )
}
export default memo(EssayQuestionPreview)
